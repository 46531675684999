/**
 * Copyright © Magento, Inc. All rights reserved.
 * See COPYING.txt for license details.
 */

define('Magento_Catalog/js/catalog-add-to-cart',[
    'jquery',
    'mage/translate',
    'underscore',
    'Magento_Catalog/js/product/view/product-ids-resolver',
    'jquery-ui-modules/widget'
], function ($, $t, _, idsResolver) {
    'use strict';

    $.widget('mage.catalogAddToCart', {
        options: {
            processStart: null,
            processStop: null,
            bindSubmit: true,
            minicartSelector: '[data-block="minicart"]',
            messagesSelector: '[data-placeholder="messages"]',
            productStatusSelector: '.stock.available',
            addToCartButtonSelector: '.action.tocart',
            addToCartButtonDisabledClass: 'disabled',
            addToCartButtonAddedClass: 'added',
            addToCartButtonTextWhileAdding: '',
            addToCartButtonTextAdded: '',
            addToCartButtonTextDefault: ''
        },

        /** @inheritdoc */
        _create: function () {
            if (this.options.bindSubmit) {
                this._bindSubmit();
            }
        },

        /**
         * @private
         */
        _bindSubmit: function () {
            var self = this;

            if (this.element.data('catalog-addtocart-initialized')) {
                return;
            }

            this.element.data('catalog-addtocart-initialized', 1);
            this.element.on('submit', function (e) {
                e.preventDefault();
                self.submitForm($(this));
            });
        },

        /**
         * @private
         */
        _redirect: function (url) {
            var urlParts, locationParts, forceReload;

            urlParts = url.split('#');
            locationParts = window.location.href.split('#');
            forceReload = urlParts[0] === locationParts[0];

            window.location.assign(url);

            if (forceReload) {
                window.location.reload();
            }
        },

        /**
         * @return {Boolean}
         */
        isLoaderEnabled: function () {
            return this.options.processStart && this.options.processStop;
        },

        /**
         * Handler for the form 'submit' event
         *
         * @param {jQuery} form
         */
        submitForm: function (form) {
            var addToCartButton, self = this;
            if (form.has('input[type="file"]').length && form.find('input[type="file"]').val() !== '') {
                self.element.off('submit');
                // disable 'Add to Cart' button
                addToCartButton = $(form).find(this.options.addToCartButtonSelector);
                addToCartButton.prop('disabled', true);
                addToCartButton.addClass(this.options.addToCartButtonDisabledClass);
                form.submit();
            } else {
                self.ajaxSubmit(form);
            }
        },

        /**
         * @param {jQuery} form
         */
        ajaxSubmit: function (form) {
            var self = this,
                productIds = idsResolver(form),
                formData;

            $(self.options.minicartSelector).trigger('contentLoading');
            self.disableAddToCartButton(form);
            formData = new FormData(form[0]);

            $.ajax({
                url: form.attr('action'),
                data: formData,
                type: 'post',
                dataType: 'json',
                cache: false,
                contentType: false,
                processData: false,

                /** @inheritdoc */
                beforeSend: function () {
                    if (self.isLoaderEnabled()) {
                        $('body').trigger(self.options.processStart);
                    }
                },

                /** @inheritdoc */
                success: function (res) {
                    var eventData, parameters;

                    $(document).trigger('ajax:addToCart', {
                        'sku': form.data().productSku,
                        'productIds': productIds,
                        'form': form,
                        'response': res
                    });

                    if (self.isLoaderEnabled()) {
                        $('body').trigger(self.options.processStop);
                    }
                    // expand mini cart on product added successfully
                    var successJs = true;
                    $('[data-block="minicart"]').on('contentUpdated', function ()  {
                        if ($(window).width() < 767){
                        $('#btn-minicart-close').on('click', function(){
                            $('.minicart-wrapper.active .minicart-mobile .block-minicart.mobile').removeClass('toggle--active-added');
                        });
                        if ($('.minicart-wrapper .showcart .counter').hasClass('empty')){
                            $('.minicart-wrapper.active .minicart-mobile').addClass('empty-cart');
                            $('.empty-cart').css('display','none');
                         }
                        }
                          if ($('.minicart-wrapper .showcart .counter').hasClass('empty')){
                             $('.block-minicart.desktop #minicart-content-wrapper').addClass('empty-cart');
                          }
                        $('.icon--close-wrapper').on('click', function(){
                            $('.block-minicart.desktop #minicart-content-wrapper').addClass('close-popup');
                        });
                      $('[data-block="minicart"]').find('[data-role="dropdownDialog"]').dropdownDialog("open");

                          $(document).ready(function() {
                              var checkMsg = $('div.message-success').length;
                              if(checkMsg == 1) {
                                $('#minicart-success-message').html('<i class="fa fa-check-circle" aria-hidden="true"></i>'+$t('SUCCESSFULLY ADDED'));
                              } else {
                                $('#minicart-success-message').html('');
                              } 
                              const successMsg = setTimeout(function () {
                                    $('#minicart-success-message').hide();
                              }, 5000);
                              clearTimeout(successMsg);
                           });

                            setTimeout(function() {
                                //$('[data-block="minicart"]').find('[data-role="dropdownDialog"]').dropdownDialog("close");
                               //$('.minicart-transition').slideDown(1500);
                            //  $('#minicart-content-wrapper').addClass('toggle--active-added');
                            }, 500);
                            if(successJs) {
                                $('#stOverlay').show().css({'z-index':'10'});

                               setTimeout(function() {
                                    $('[data-block="minicart"]').find('[data-role="dropdownDialog"]').dropdownDialog("close");
                                    //$('#minicart-content-wrapper').removeClass('minicart-transition');
                                   $('.minicart-wrapper').removeClass('active');
                                   $('#stOverlay').hide();
                               }, 5000);
                              //  setTimeout(function() {
                                  //  $('.minicart-transition').slideUp(3000);
                              // },4700);

                                successJs = false;
                            }

                            // ----toogle dark overlay when clicked----
                            $('#stOverlay').click(function() {
                                $('#stOverlay').hide();
                            });
                            // ----toogle dark overlay when clicked----
                       // }

                    });

                    if (res.backUrl) {
                        eventData = {
                            'form': form,
                            'redirectParameters': []
                        };
                        // trigger global event, so other modules will be able add parameters to redirect url
                        $('body').trigger('catalogCategoryAddToCartRedirect', eventData);

                        if (eventData.redirectParameters.length > 0) {
                            parameters = res.backUrl.split('#');
                            parameters.push(eventData.redirectParameters.join('&'));
                            res.backUrl = parameters.join('#');
                        }

                        self._redirect(res.backUrl);

                        return;
                    }

                    if (res.messages) {
                        $(self.options.messagesSelector).html(res.messages);
                    }

                    if (res.minicart) {
                        $(self.options.minicartSelector).replaceWith(res.minicart);
                        $(self.options.minicartSelector).trigger('contentUpdated');
                    }

                    if (res.product && res.product.statusText) {
                        $(self.options.productStatusSelector)
                            .removeClass('available')
                            .addClass('unavailable')
                            .find('span')
                            .html(res.product.statusText);
                    }
                    self.enableAddToCartButton(form);
                },

                /** @inheritdoc */
                error: function (res) {
                    $(document).trigger('ajax:addToCart:error', {
                        'sku': form.data().productSku,
                        'productIds': productIds,
                        'form': form,
                        'response': res
                    });
                },

                /** @inheritdoc */
                complete: function (res) {
                    if (res.state() === 'rejected') {
                        location.reload();
                    }
                }
            });
        },

        /**
         * @param {String} form
         */
        disableAddToCartButton: function(form) {
            var addToCartButtonTextWhileAdding = this.options.addToCartButtonTextWhileAdding || $t('Adding...'),
                addToCartButton = $(form).find(this.options.addToCartButtonSelector);

            addToCartButton.addClass(this.options.addToCartButtonDisabledClass);
            addToCartButton.find('span').text(addToCartButtonTextWhileAdding);
            addToCartButton.attr('title', addToCartButtonTextWhileAdding);
        },

        /**
         * @param {String} form
         */
        enableAddToCartButton: function(form) {
            var addToCartButtonTextAdded = this.options.addToCartButtonTextAdded || $t('SUCCESSFULLY ADDED');
            var self = this,
                addToCartButton = $(form).find(this.options.addToCartButtonSelector);

            addToCartButton.removeClass(self.options.addToCartButtonDisabledClass).addClass(this.options.addToCartButtonAddedClass);
            addToCartButton.find('span').text(addToCartButtonTextAdded);
            addToCartButton.attr('title', addToCartButtonTextAdded);
            $('body').on('click', function(){
                $('.block-minicart.desktop #minicart-content-wrapper').removeClass('toggle--active-added');
            })
          setTimeout(function() {
                var addToCartButtonTextDefault = self.options.addToCartButtonTextDefault || $t('Add to Cart');

                addToCartButton.removeClass(self.options.addToCartButtonDisabledClass).removeClass(self.options.addToCartButtonAddedClass);
                addToCartButton.find('span').text(addToCartButtonTextDefault);
                addToCartButton.attr('title', addToCartButtonTextDefault);
                $('.block-minicart.desktop #minicart-content-wrapper,.block-minicart.mobile').addClass('toggle--active-added');
                //automatically close
                setTimeout(function(){
                    $('.block-minicart.mobile').removeClass("toggle--active-added");
                    $('.minicart-mobile').css("display","none");
                }, 5000);
                //
                $('.block-minicart.desktop #minicart-content-wrapper,.minicart-wrapper.active .minicart-mobile .block-minicart.mobile').removeClass('empty-cart');
                $('.showcart').removeClass('empty-dup-cart');
                 $('.block-minicart.desktop #minicart-content-wrapper').removeClass('close-popup');
            }, 3000);
            //$("html, body").animate({ scrollTop: 0 }, "slow");
        }
    });

    return $.mage.catalogAddToCart;
});

