define('Born_Bopis/js/product/available-stores',[
    'jquery',
    'uiComponent',
    'ko',
    'mage/translate',
    'Magento_Ui/js/modal/modal',
    'Born_Bopis/js/map-loader',
    'Born_Bopis/js/product/stores-service'
], function ($, Component, ko, $t, popupModal, MapLoader, storeService) {
    'use strict';

    let self;
    return Component.extend({
        /**
         * observable store items
         */
        storeItems: ko.observable(null),

        /**
         * observable total count string
         */
        storesCountText: ko.observable(null),

        /**
         * set default options.
         */
        defaults: {
            items : [],
            template: 'Born_Bopis/available-stores/container',
            storeListTemplate: 'Born_Bopis/available-stores/storeList',
            product_qty: false,
            product_id: false
        },

        /**
         * init app
         */
        initialize: function () {
            self = this;
            this._super();
            popupModal({
                type: 'popup',
                responsive: true,
                innerScroll: true,
                buttons: []
            }, $('#available-stores-popup'));
            $(window).on('available.stores.change', this.updateAvailableStoresBlock.bind(this));
            if (this.product_qty) {
                let boxQty = this.product_qty, qtyBoxHtml = '', i;
                if (this.product_qty > 10) {
                    boxQty = 10;
                }
                for (i = 1; i <= boxQty; i++) {
                    qtyBoxHtml += '<option value="' + i + '">' + i + '</option>';
                }
                $('#product_addtocart_form #qty').html(qtyBoxHtml);
            }
            $('[class*="swatch-opt"]').on('click', '.swatch-option', function() {
                let selectedProduct = storeService.getSelectedProducts();
                if (selectedProduct.length) {
                    let allProductQty = $('[data-role=swatch-options]').data('mage-SwatchRenderer').options.jsonConfig.qty,
                        qtyBoxHtml = '', i, boxQty = 0;
                    boxQty = allProductQty[selectedProduct[0]];
                    if (boxQty > 10) {
                        boxQty = 10;
                    }
                    for (i = 1; i <= boxQty; i++) {
                        qtyBoxHtml += '<option value="' + i + '">' + i + '</option>';
                    }
                    $('#product_addtocart_form #qty').html(qtyBoxHtml);
                }
            });
        },

        /**
         * update fetched stores in list
         * @param e
         * @param params
         */
        updateAvailableStoresBlock: function (e, params) {
            if (params) {
                self.processStoreItems(params.data);
                self.items = params.data;
            } else {
                self.processStoreItems([]);
                self.items = params.data;
            }
        },

        /**
         * open popup modal and init google map
         * @param items
         */
        processStoreItems: function (items) {
            $("#available-stores-popup").modal("openModal");
            self.storesCountText($.mage.__('Available in %1 stores').replace('%1', items.length.toString()));
            self.storeItems(items);
            MapLoader.done($.proxy(storeService.initMap(items), this)).fail(function() {
                console.error("ERROR: Google maps library failed to load");
            });
        },

        /**
         * set current location on google map
         */
        setCurrentLocation: function () {
          storeService.setCurrentLocation();
        },

        /**
         *
         * @param item
         */
        selectItem: function (item) {
            storeService.selectItem(item);
            $('.search-result [role="alert"]').hide();
        },

        /**
         * On click of pick and collect delivery process
         * @param data
         * @param event
         */
        storeSearch: function (data, event) {
            storeService.storeSearch(data, event)
        },

        /**
         * prepare address format to show
         * @param item
         * @returns {string}
         */
        prepareAddress: function (item) {
            return item.address1 + ', ' + item.state + ', ' + item.city;
        },

        /**
         * Do nothing on store item render.
         * @param ele
         */
        restoreStoredQuoteData: function (ele) {
            return null;
        }
    });
});

