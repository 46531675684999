define('Born_Bopis/js/map-loader',['jquery'], function($) {
    var google_maps_loaded_def = null;
    var key = window.bopisConfig.api_key;
    if (!google_maps_loaded_def) {
        google_maps_loaded_def = $.Deferred();
        window.google_maps_loaded = function() {
            google_maps_loaded_def.resolve(google.maps);
        };
        require(['https://maps.googleapis.com/maps/api/js?key='+ key +'&callback=google_maps_loaded&libraries=places'],
            function() {},
            function(err) {
                console.log(err);
                google_maps_loaded_def.reject();
            }
        );
    }

    return google_maps_loaded_def.promise();
});

