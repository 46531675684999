define('Born_Bopis/js/product/fetch-sources',['jquery', 'Born_Bopis/js/product/stores-service'], function($, storesService) {
    "use strict";

    /**
     * declare widget.
     */
    $.widget('productSources.ajax', {
        /**
         * set default options.
         */
        options: {
            method: 'get',
            triggerEvent: 'click',
            ajaxData: {}
        },

        /**
         * @private
         */
        _create: function() {
            this._bind();
        },

        /**
         * @private
         */
        _bind: function() {
            var self = this;
            self.element.on(self.options.triggerEvent, function(event) {
                event.preventDefault();
                if (self.validate()) {
                    self._ajaxSubmit();
                } else {
                    $('#product-options-wrapper').scroll();
                }
            });
        },

        /**
         * ajax request
         * @private
         */
        _ajaxSubmit: function() {
            /**
             * @todo not working for urls
             * @type {availableStores.ajax}
             */
            var self = this;
            $.ajax({
                url: self.options.ajaxUrl,
                type: self.options.method,
                data: self.options.ajaxData,
                dataType: 'json',
                beforeSend: function() {
                    $('body').trigger('processStart');
                },
                success: function(result) {
                    $('body').trigger('processStop');
                    $(window).trigger('available.stores.change', {
                        data: self.refactorItems(result)
                    });
                }
            });
        },

        /**
         * validate add to cart form.
         * @returns {boolean}
         */
        validate: function() {
            let foundIds = [];
            let quantity = $('#product_addtocart_form #qty').val();
            if ($('.swatch-attribute').length) {
                foundIds = storesService.getSelectedProducts();
                if (!foundIds.length) {
                    const swatchErrorTitle = 'Choose Your Size';
                    $('.swatch-attribute .swatch-attribute-label').append('<div class="swatch-error-notice">' + swatchErrorTitle + '</div>');
                    $('.swatch-attribute .swatch-option').addClass('error');
                    return false;
                }
            } else {
                foundIds.push(this.options.product_id);
            }
            this.options.ajaxData = {product:foundIds, qty:quantity};

            return true;
        },

        /**
         * prepare items from result.
         * @param result
         * @returns {any[]}
         */
        refactorItems: function (result) {
            if (typeof(result.success) != "undefined" && result.success) {
                return result.data;
            }

            return [];
        }
    });

    return $.productSources.ajax;
});

