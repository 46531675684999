define('Born_Bopis/js/product/stores-service',[
    'jquery',
    'mage/translate',
    'jquery/validate'
], function ($, $t) {
    "use strict";

    /**
     * return store common methods in the object.
     */
    return {
        map: false,
        markers: new Array(),
        infowindow: false,
        /**
         * search stores by term and return.
         * @param data
         * @param event
         */
        storeSearch: function (data, event) {
            let searchTerm = $.trim($(event.target).val().toLowerCase()),
                searchItems = new Array();
            $.each(data.items, function (key, item) {
                if (
                    item.city.toLowerCase().indexOf(searchTerm) !== -1
                    || item.zipCode.toLowerCase().indexOf(searchTerm) !== -1
                ) {
                    searchItems.push(item);
                }
            });
            if (!searchTerm) {
                data.storeItems(data.items);
            } else {
                data.storeItems(searchItems);
            }
            data.storesCountText($.mage.__('Available in %1 stores').replace('%1', searchItems.length.toString()));
        },

        /**
         * Add selected class to the item.
         * @param data
         * @param event
         */
        selectItem: function (item) {
            $('ul.stores-list li.store-item.selected').each(function (i) {
                $(this).removeClass('selected');
            });
            let $target = $('[data-store-code="' + item.storeCode + '"]');
            $target.addClass('selected');
            if ($('#pickup_store').length) {
                $('#pickup_store').val(item.storeCode);
                this.storeAddressToShippingAddress(item);
            }
            if ($('body').hasClass('bopis__checkout--container')) {
                $('html, body').animate({
                    scrollTop: $(".bopis__checkout--container #stores-map").offset().top
                }, 2000);
            }
            this.highlightStore(item.storeCode, 16);
        },

        /**
         * Fill shipping address.
         * @param storeitem
         */
        storeAddressToShippingAddress: function (storeitem) {
            const storeName = storeitem.storeName;
            var sname = storeName.replace(/[^a-zA-Z ]/g, "");
            let regionId = $('option[data-title="' + storeitem.state + '"]').attr('value'),
                telephone = (storeitem.storePhone) ? storeitem.storePhone : window.bopisConfig.customer_service_number,
                shippingFieldsMap = [
                    ['input[name="firstname"]', sname],
                    ['input[name="lastname"]', storeitem.storeCode],
                    ['input[name="street[0]"]', storeitem.address1],
                    ['select[name="region_id"]', regionId],
                    ['input[name="region"]', storeitem.state],
                    ['select[name="city_id"]', storeitem.city],
                    ['input[name="city"]', storeitem.city],
                    ['select[name="district_id"]', storeitem.district],
                    ['input[name="district"]', storeitem.district],
                    ['input[name="postcode"]', storeitem.zipCode],
                    ['input[name="telephone"]', telephone]
                ];
            $.each(shippingFieldsMap, function (key, shippingElement) {
                $(shippingElement[0]).val(shippingElement[1]);
                if (shippingElement[0] === 'select[name="city_id"]'
                    || shippingElement[0] === 'select[name="district_id"]'
                ) {
                    $(shippingElement[0] + ' option:eq(1)').attr('selected', 'selected');
                    $(shippingElement[0]).change();
                }
                $(shippingElement[0]).keyup();
            });
        },

        /**
         * initialize the google map.
         * @param stores
         */
        initMap: function (stores) {
            this.map = new window.google.maps.Map(document.getElementById('stores-map'), {
                zoom: parseInt(window.bopisConfig.default_zoom),
                center: new window.google.maps.LatLng(parseFloat(window.bopisConfig.default_lat), parseFloat(window.bopisConfig.default_lng)),
                disableDefaultUI: true
            });

            this.infowindow = new window.google.maps.InfoWindow();

            var marker, i, self = this;
            $.each(stores, function (key, store) {
                marker = new window.google.maps.Marker({
                    position: new window.google.maps.LatLng(parseFloat(store.latitude), parseFloat(store.longitude)),
                    map: self.map
                });
                self.markers[store.storeCode] = marker;
                window.google.maps.event.addListener(marker, 'click', (function (marker, i) {
                    return function () {
                        infowindow.setContent(store.storeName);
                        infowindow.open(self.map, marker);
                    }
                })(marker, i));
            });
        },

        /**
         * Highlight store on google map.
         * @param storeCode
         */
        highlightStore: function (storeCode, zoom) {
            var self = this;
            self.markers[storeCode].setAnimation(window.google.maps.Animation.BOUNCE);
            setTimeout(function () {
                self.markers[storeCode].setAnimation(null);
            }, 1000);
            self.map.setCenter(this.markers[storeCode].getPosition());
            self.map.setZoom(zoom);
        },

        /**
         * set current location.
         * @returns {boolean}
         */
        setCurrentLocation: function () {
            var self = this;
            if (!self.map) {
                return false;
            }
            if (window.navigator.geolocation) {
                window.navigator.geolocation.getCurrentPosition(function (position) {
                    var pos = {
                        lat: position.coords.latitude,
                        lng: position.coords.longitude
                    };

                    self.infowindow.setPosition(pos);
                    self.map.setCenter(pos);
                    self.map.setZoom(10);
                }, function () {
                    self.handleLocationError(true);
                });
            } else {
                self.handleLocationError(false);
            }
        },
        handleLocationError: function (browserHasGeolocation) {
            this.infowindow.setContent(browserHasGeolocation ?
                'Error: The Geolocation service failed.' :
                'Error: Your browser doesn\'t support geolocation.');
            this.infowindow.open(this.map);
        },

        /**
         * Find selected swatch and fetch product id from json configuration.
         * @returns {[]}
         */
        getSelectedProducts: function () {
            $('.swatch-error-notice').remove();
            $('.swatch-attribute .swatch-option').removeClass('error');
            let selected_options = {};
            $('div.swatch-attribute').each(function (k, v) {
                let attribute_id    = $(v).attr('attribute-id'),
                    option_selected = $(v).attr('option-selected');
                if (!attribute_id || !option_selected) {
                    return;
                }
                selected_options[attribute_id] = option_selected;
            });
            let product_id_index = $('[data-role=swatch-options]').data('mage-SwatchRenderer').options.jsonConfig.index,
                foundIds = [];
            $.each(product_id_index, function (product_id, attributes) {
                let productIsSelected = function (attributes, selected_options) {
                    return _.isEqual(attributes, selected_options);
                };
                if (productIsSelected(attributes, selected_options)) {
                    foundIds.push(product_id);
                }
            });

            return foundIds;
        }
    };
});

