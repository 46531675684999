define('Born_Buynow/js/buy-now',[
    'jquery',
    'Magento_Customer/js/model/authentication-popup',
    'Magento_Customer/js/customer-data'
], function ($, authenticationPopup, customerData) {
    "use strict";
    return function (config, element) {
        $(element).click(function () {
            var form = $(config.form),
                baseUrl = form.attr('action'),
                addToCartUrl = 'checkout/cart/add',
                buyNowCartUrl = 'buynow/cart/add',
                buyNowUrl = baseUrl.replace(addToCartUrl, buyNowCartUrl),
                isGuestCheckoutAllow = config.isGuestCheckoutAllow,
                    customer = customerData.get('customer');
            form.attr('action', buyNowUrl);
            event.preventDefault();
            if (!customer().firstname && isGuestCheckoutAllow == 0) {
                authenticationPopup.showModal();

                return false;
            } else {
                if (form.valid()) {
                    $.ajax({
                        type: form.attr('method'),
                        url: form.attr('action'),
                        data: form.serialize(),
                        success: function (data) {
                            form.attr('action', baseUrl);
                            if (data.hasOwnProperty('backUrl')) {
                                window.location.href = data.backUrl;
                            } else {
                                window.location.reload(true);
                            }
                        }
                    });
                }
            }
            return false;
        });
    }
});

